import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { IconButton, InputAdornment } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import CssBaseline from "@mui/material/CssBaseline"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { ThemeProvider, createTheme, styled } from "@mui/material/styles"
import { useFormik } from "formik"
import * as React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import backgroundImage from "../../assets/images/frontImage.svg"
import Logo from "../../assets/images/logo.svg"
import shadow from "../../assets/images/shadow.png"
import { AppDispatch } from "../../config/store"
import { fetchAuthInfo } from "../../reducers/authInfoSlice"
import { setEmailPassword } from "../../reducers/emailPasswordSlice"
function Copyright(props: any) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"© "}
      {new Date().getFullYear()}
      {" Copyright  "}
      <Link
        color="inherit"
        href="https://fleet.10.co.bw/"
        sx={{ color: "#4191ff" }}
      >
        10XTECHNOLOGIES
      </Link>{" "}
      {"All Rights Reserved ."}
    </Typography>
  )
}

const defaultTheme = createTheme()

const ShadowImage = styled("img")({
  position: "absolute",
  top: 0,
  right: 0,
  width: "auto",
  height: "auto",
  maxHeight: "20vh",
  maxWidth: "20vw",
  "@media (max-width: 1280px)": {
    maxHeight: "15vh",
    maxWidth: "15vw",
  },
  "@media (max-width: 960px)": {
    maxHeight: "20vh",
    maxWidth: "20vw",
  },
  "@media (max-width: 600px)": {
    maxHeight: "30vh",
    maxWidth: "30vw",
  },
  "@media (max-width: 320px)": {
    maxHeight: "20vh",
    maxWidth: "20vw",
  },
})

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(emailRegex, "In-correct email"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Wrong password"),
})

const LoginPage = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleForgotPasswordClick = () => {
    navigate("/forgotpassword")
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLogin,
  })

  async function handleLogin() {
    debugger
    try {
      const response = await fetch(
         `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/login?userEmail=${formik.values.email}&userPassword=${formik.values.password}`,
      //  "http://192.168.101.73:8083/fleet-auth-service//login?userEmail=${formik.values.email}&userPassword=${formik.values.password}",
        {
          method: "POST",
        }
      )
      if (response.ok) {
        const data = await response.json()
        if (data.access_token) {
          dispatch(
            setEmailPassword({
              email: formik.values.email,
              password: formik.values.password,
            })
          )
          toast.success("Login successful")
          dispatch(fetchAuthInfo(data))
          navigate("/dashboard", {
            state: {
              email: formik.values.email,
              password: formik.values.password,
            },
          })
        } else {
          toast.error("Invalid credentials")
        }
      } else {
        toast.error("Failed to login")
      }
    } catch (error) {
      toast.error("Failed to login")
      console.error(error)
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={8}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <ShadowImage src={shadow} alt="Shadow" />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <img src={Logo} alt="Logo" className="logo" />
            <Typography className="title">Log in to your account</Typography>
            <Typography className="subtitle">
              {" "}
              Welcome back! Please enter your details.
            </Typography>

            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1, width: "85%" }}
            >
              <TextField
                name="email"
                label="Email"
                placeholder="Enter the Email ID"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <Box className="error-text">{formik.errors.email}</Box>
              )}
              <TextField
                name="password"
                label="Password"
                placeholder="Enter the password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.touched.password && formik.errors.password && (
                <Box className="error-text">{formik.errors.password}</Box>
              )}
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                </Grid>
                <Grid item onClick={handleForgotPasswordClick}>
                  <Typography sx={{ color: "#1366D9", cursor: "pointer" }}>
                    Forgot Password?
                  </Typography>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#EB6247",
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
          <Box sx={{ pb: 2 }}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default LoginPage
