import { Add as AddIcon } from "@mui/icons-material"
import {
  Box,
  Breadcrumbs,
  Card,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material"
import { jwtDecode } from "jwt-decode"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { AppDispatch } from "../../config/store"

import AppHeader from "../../layouts/AppHeader"
import { clearAuthInfo } from "../../reducers/authInfoSlice"
import { DashboardData, FacilityPayload } from "../../services/data"
import "../../styles/dashboard.css"
import { DashboardModel } from "../../types/dashboard.model"

export default function Dashboard() {
  const authInfo = useSelector((state: any) => state.authInfo.data)
  const emailPassword = useSelector((state: any) => state.emailPassword)
  const emailId = emailPassword.email
  // console.log(emailId, "emailId1")
  const Password = emailPassword.password

  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const [loading, setLoading] = React.useState(true)
  const [matchingData, setMatchingData] = React.useState<
    DashboardModel[] | undefined
  >(undefined)
  const [roleInfo, setRoleInfo] = React.useState([])
  const { state } = location

  React.useEffect(() => {
    const token = authInfo.access_token
    const decoded = jwtDecode(token) as { [key: string]: any }
    const roleInfo = decoded.resource_access["tenx-rbac"].roles
    // console.log(roleInfo, "roleInfo123")
    setRoleInfo(roleInfo)
    if (roleInfo && Array.isArray(roleInfo)) {
      const matchingItems = DashboardData.filter((item) =>
        roleInfo.includes(item.title)
      )
      setMatchingData(matchingItems)
    }
  }, [authInfo])

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const facilityAuth = async (path: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FACILITY_ENDPOINT}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(FacilityPayload),
        }
      )

      if (response.ok) {
        const responseData = await response.json()
        const token = responseData.token
        window.open(`${path}?accessToken=${token}`, "_blank")
      } else {
        console.error("Login failed")
      }
    } catch (error) {
      console.error("Error during login:", error)
    }
  }

  const traccarAuth = (path: string) => {
    const refreshToken = authInfo.refresh_token
    const accessToken = authInfo.access_token
    let btoaEmail = btoa(emailId)
    let btoaPassword = btoa(Password)
    let role = roleInfo.filter((i: any) => i.includes("traccar"))
    // console.log("role", role)
    const childWindow = window.open(
      `${path}?username=${btoaEmail}&password=${btoaPassword}&refreshToken=${refreshToken}&role=${role}&accessToken=${accessToken}`,
      "_blank"
    )

    if (!childWindow) {
      console.error("Child window could not be opened")
    } else {
      window.addEventListener("message", (event) => {
        if (event.source === childWindow && event.data.exit) {
          dispatch(clearAuthInfo())
        }
      })
    }
  }

  const inventoryAuth = (path: string) => {
    const accessToken = authInfo.access_token
    window.open(`${path}/?accessToken=${accessToken}`, "_blank")
  }

  const handelRedirection = (data: any) => {
    if (data !== null) {
      switch (data.id) {
        case 1:
          facilityAuth(data.path)
          break
        case 3:
          inventoryAuth(data.path)
          break
        case 5:
          traccarAuth(data.path)
          break
        default:
          break
      }
    }
  }

  return (
    <>
      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box sx={{ background: "#f1f4f7" }}>
          <AppHeader />
          <Box
            sx={{
              height: {
                xl: "100vh",
                lg: "100vh",
                md:
                  matchingData && matchingData.length === 0 ? "100vh" : "100%",
                sm:
                  matchingData && matchingData.length === 0 ? "100vh" : "100%",
                xs:
                  matchingData && matchingData.length === 0 ? "100vh" : "100%",
              },
              margin: "auto",
              width: "80%",
            }}
            // sx={{ backgroundColor: { xs: "red", md: "green", lg: "blue" }, }}
          >
            <Box style={{ padding: "40px 0px" }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="none"
                  style={{ color: "#A2ADD0", fontSize: "14px" }}
                  href="/dashboard"
                >
                  HOME
                </Link>
                <Typography
                  color="#4191FF"
                  fontSize="14px"
                  sx={{ cursor: "pointer" }}
                >
                  DASHBOARD
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              {!loading &&
                (matchingData && matchingData.length === 0 ? (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Card
                        sx={{
                          width: "100%",
                          minHeight: "170px",
                          display: "flex",
                        }}
                        className="card-design"
                      >
                        <Grid
                          container
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                          // sx={{
                          //   padding: ["50px 100px", "50px 200px"],
                          //   height: "170px",
                          //   maxHeight: "170px",
                          //   "@media (min-width: 600px)": {
                          //     padding: "50px 200px",
                          //   },
                          // }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ textAlign: "center" }}
                          >
                            <AddIcon
                              fontSize="small"
                              sx={{
                                color: "grey",
                                padding: "20px",
                                borderRadius: "50%",
                                background: "#e4e5e7",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  <Box>
                    <Grid container spacing={2}>
                      {matchingData &&
                        matchingData.map((item, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={4}
                            sx={{
                              cursor: "pointer",
                            }}
                            key={item.id}
                          >
                            <Card
                              className="card-design"
                              onClick={() => handelRedirection(item)}
                            >
                              <Grid
                                container
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                  padding: "0px 10px",
                                  height: "170px",
                                  maxHeight: "170px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  sx={{ px: 2 }}
                                >
                                  <img
                                    src={item.image}
                                    alt="facility_missing"
                                    className="dashboard-image"
                                    style={{
                                      maxWidth: "100%",
                                      height: "auto",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      display: "block",
                                      ...(window.innerWidth <= 600 && {
                                        display: "block",
                                      }),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={9}
                                  md={9}
                                  lg={9}
                                  xl={9}
                                  sx={{ px: 2 }}
                                >
                                  <Box>
                                    <Typography
                                      variant="h6"
                                      color="#282828"
                                      fontWeight={700}
                                      sx={{
                                        whiteSpace: "nowrap",
                                        "@media (max-width: 600px)": {
                                          fontSize: "16px",
                                        },
                                      }}
                                    >
                                      {item.title}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      mt: 2,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textAlign: "left",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      color="#A2ADD0"
                                      fontSize={14}
                                      className="description"
                                    >
                                      {item.short_description}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        ))}
                      {matchingData &&
                        matchingData.length < DashboardData.length + 1 && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={4}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Card className="card-design">
                              <Grid
                                container
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                  padding: "0px 10px",
                                  height: "170px",
                                  maxHeight: "170px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  sx={{ textAlign: "center" }}
                                >
                                  <AddIcon
                                    fontSize="small"
                                    sx={{
                                      color: "grey",
                                      padding: "20px",
                                      borderRadius: "50%",
                                      background: "#e4e5e7",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        )}
                    </Grid>
                  </Box>
                ))}
            </Box>
          </Box>

          <Box className="app-footer">
            © 2024 Copyright{" "}
            <span className="app-footer-link">10XTECHNOLOGIES</span>. All Rights
            Reserved
          </Box>
        </Box>
      )}
    </>
  )
}
