import asset from "../assets/images/asset-management.svg"
import facility from "../assets/images/facility-management.svg"
import fleet from "../assets/images/fleet-management.svg"
import inventory from "../assets/images/inventory-management.svg"
import vehicle from "../assets/images/vehicle-tracking.svg"
import { DashboardModel } from "../types/dashboard.model"

export const DashboardData: DashboardModel[] = [
  {
    id: 1,
    image: facility,
    title: "Facility Management",
    short_description:
      "Facility management involves the effective coordination of people, place, process, and technology to optimize the functionality, safety, and efficiency of built environments.",
    path: "https://thingsboard.iotcomms.co.bw/dashboard/77675220-dac0-11ee-b265-857ff0088da6",
  },
  {
    id: 2,
    image: fleet,
    title: "Fleet Management",
    short_description:
      "Fleet management is the strategic oversight and optimization of a collection of vehicles to ensure efficiency, safety, and cost-effectiveness in operations.",
    path: null,
  },
  {
    id: 3,
    image: inventory,
    title: "Inventory Management",
    short_description:
      "Inventory management is the strategic supervision of stock levels, storage, and distribution to optimize efficiency, minimize costs, and meet demand effectively.",
    path: "https://faf.co.bw/inventory",
  },
  {
    id: 4,
    image: asset,
    title: "Asset Management",
    short_description:
      "Asset management involves strategically overseeing and maximizing the value, performance, and lifespan of physical, financial, or intangible assets.",
    path: null,
  },
  {
    id: 5,
    image: vehicle,
    title: "Vehicle Tracking",
    short_description:
      "Vehicle tracking is the real-time monitoring and management of vehicle location, speed, and status for enhanced fleet efficiency and security.",
        // path: "https://fleet.10x.co.bw/vehicle-tracking",
      //  path: "http://localhost:3001/vehicle-tracking",
          path: "https://faf.co.bw/vehicle-tracking",
  },
]

export const FacilityPayload: any = {
  username: "kabo@10x.co.bw",
  password: "Password123",
}
