import { AppBar, Avatar, Box, Drawer, IconButton, Toolbar } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import bell from "../assets/images/bell.svg"
import email from "../assets/images/email.svg"
import logo from "../assets/images/logo.svg"
import search from "../assets/images/search.svg"
import setting from "../assets/images/setting.svg"
import "../styles/app-header.css"

import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import LogoutIcon from "@mui/icons-material/Logout"
import MenuIcon from "@mui/icons-material/Menu"
import PersonIcon from "@mui/icons-material/Person"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { AppDispatch } from "../config/store"
import { clearAuthInfo } from "../reducers/authInfoSlice"
export default function AppHeader() {
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useDispatch<AppDispatch>()
  const authInfo = useSelector((state: any) => state.authInfo.data)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      const refreshTokenlogout = authInfo.refresh_token;
      // console.log(refreshTokenlogout, "refreshTokenlogout");
      const keycloakResponse = await fetch(
        `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/logout?refreshToken=${refreshTokenlogout}`,
        {
          method: "POST",
        }
      );
  
      if (!keycloakResponse.ok) {
        throw new Error("Failed to logout from Keycloak");
      }
      dispatch(clearAuthInfo());
      const TraccarLogout = await fetch("https://fafco.bw/api/session", {
        method: "DELETE",
      });
  
      if (!TraccarLogout.ok) {
        throw new Error("Failed to logout from fleet session");
      }
  
      navigate("/");
  
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  
  
  const handleMyAccountClick = () => {
    handleClose()
    navigate("/account")
  }
  return (
    <Box>
      <AppBar
        position="sticky"
        sx={{ boxShadow: "0px 4px 20px 0px #244F961A" }}
      >
        <Toolbar
          sx={{
            background: "#ffffff",
            boxShadow: "0px 4px 20px 0px #244F961A",
          }}
        >
          <Box sx={{ flexGrow: 1, p: 1, mx: 2 }}>
            <Link to="/dashboard">
              <img src={logo} alt="logo_missing" className="toolbar-logo" />
            </Link>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              borderLeft: "1px solid #D4D9E0",
              alignItems: "center",
            }}
          >
            <IconButton
              disableRipple
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "0px",
                ml: 3,
              }}
            >
              <img
                src={search}
                className="toolbar-icons"
                alt="search_missing"
              />
            </IconButton>

            <IconButton
              sx={{
                background: "#F3F6F9",
                width: "40px",
                height: "40px",
                borderRadius: "7px",
                m: 1,
                ml: 10,
              }}
            >
              <img
                src={setting}
                className="toolbar-icons"
                alt="setting_missing"
              />
            </IconButton>
            <IconButton
              sx={{
                background: "#F3F6F9",
                width: "40px",
                height: "40px",
                borderRadius: "7px",
                m: 1,
              }}
            >
              <img src={email} className="toolbar-icons" alt="email_missing" />
            </IconButton>
            <IconButton
              sx={{
                background: "#F3F6F9",
                width: "40px",
                height: "40px",
                borderRadius: "7px",
                m: 1,
              }}
            >
              <img src={bell} className="toolbar-icons" alt="bell_missing" />
            </IconButton>
            <IconButton disableRipple>
              <Avatar
                variant="rounded"
                style={{ backgroundColor: "#F3F6F9", color: "#67748e" }}
              />
              <Box sx={{ ml: 1, mr: 2, display: { xs: "none", md: "block" } }}>
                <Box
                  sx={{ fontSize: "16px", color: "#4191FF", fontWeight: 400 }}
                >
                  Fleet
                </Box>
                <Box
                  sx={{
                    fontSize: "15px",
                    color: "#000",
                    fontWeight: 400,
                    display: "flex",
                  }}
                >
                  Admin
                </Box>
              </Box>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ autoFocus: false }}
            >
              <MenuItem onClick={handleMyAccountClick}>
                <PersonIcon sx={{ color: "#67748e" }} />
                {/* <Avatar
                  variant="rounded"
                  style={{ backgroundColor: "transparent", color: "#67748e" }}
                /> */}
                <Box sx={{ p: 0.5 }}>My Account</Box>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <LogoutIcon sx={{ color: "#67748e" }} />
                <Box sx={{ p: 0.5 }}>Sign Out</Box>
              </MenuItem>
            </Menu>
            <Box sx={{ m: 1 }}>
              <IconButton onClick={handleClick}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={openDrawer}>
        <Box
          sx={{
            width: 20,
            height: 50,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
          <IconButton>
            <img src={search} className="toolbar-icons" alt="search_missing" />
          </IconButton>
          <IconButton>
            <img
              src={setting}
              className="toolbar-icons"
              alt="setting_missing"
            />
          </IconButton>
          <IconButton>
            <img src={email} className="toolbar-icons" alt="email_missing" />
          </IconButton>
          <IconButton>
            <img src={bell} className="toolbar-icons" alt="bell_missing" />
          </IconButton>
          <IconButton disableRipple onClick={handleClick}>
            <PersonIcon />
          </IconButton>
        </Box>
      </Drawer>
    </Box>
  )
}
