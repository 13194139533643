import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Forgotpassword from "../pages/forgotpassword";
import Loginpage from "../pages/login";
import Account from "../pages/myaccount";
import Resetpassword from "../pages/resetpassword";
import ProtectedRoute from "./ProtectedRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Loginpage />,
  },
  {
    path: "/forgotpassword",
    element: <Forgotpassword />,
  },
  {
    path: "/resetpassword",
    element: <Resetpassword />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/account",
        element: <Account />,
      },
    ],
  },
])

export default function AppRouter() {
  return <RouterProvider router={router} />
}
