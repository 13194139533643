import { Tuple, combineReducers, configureStore } from "@reduxjs/toolkit"
import logger from "redux-logger"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { thunk } from "redux-thunk"
import authInfoReducer from "../reducers/authInfoSlice"
import emailPasswordReducer from '../reducers/emailPasswordSlice'

const persistConfig = {
  key: "root",
  storage,
}

const rootReducer = combineReducers({
  authInfo: authInfoReducer,
  emailPassword: emailPasswordReducer,

})

export const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: () => new Tuple<any>(thunk, logger),
})

export const persistor = persistStore(store)

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
