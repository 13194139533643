import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AppHeader from "../../layouts/AppHeader";

const AccountSettingsForm = () => {
  const authInfo = useSelector((state: any) => state.authInfo.data);
  const token = authInfo.access_token;
  const decoded = jwtDecode(token) as { [key: string]: any };
  const Firstname = decoded.family_name;
  const Lastname = decoded.given_name;
  const Emailid = decoded.email;
  const [firstName, setFirstName] = useState(Firstname || "");
  const [lastName, setLastName] = useState(Lastname || "");
  const [email, setEmail] = useState(Emailid || "");
  const [role, setRole] = useState("Admin");
  const [image, setImage] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log("Changes saved!");
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',background: "#f1f4f7" }}>
      <Box sx={{ flex: '1' }}>
        <Box sx={{  flexGrow: 1 }}>
          <AppHeader />
          <Box
            sx={{
              height: '100%',
              margin: "auto",
              width: "80%",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box style={{ padding: "40px 0px" }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="none"
                  style={{ color: "#A2ADD0", fontSize: "14px" }}
                  href="/dashboard"
                >
                  HOME
                </Link>
                <Typography
                  color="#4191FF"
                  fontSize="14px"
                  sx={{ cursor: "pointer" }}
                >
                  MY ACCOUNT
                </Typography>
              </Breadcrumbs>
            </Box>
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1",
                  marginX: "5%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "flex-start",
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #E6E6E6",
                      borderRadius: "10px",
                      background: "#fff",
                      padding: "0px 10px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        padding: "15px",
                      }}
                    >
                      Account Settings
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={12} md={8} sx={{ mt: 2 }}>
                          <TextField
                            label="First name"
                            fullWidth
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                          />
                          <TextField
                            fullWidth
                            label="Last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                          />
                          <TextField
                            fullWidth
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                          />
                          <Select
                            fullWidth
                            labelId="role-label"
                            placeholder="Choose your role"
                            value={role}
                            onChange={(e) => setRole(e.target.value as string)}
                            sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                          >
                            <MenuItem value="Admin" sx={{ justifyContent: "flex-start" }}>
                              Admin
                            </MenuItem>
                            <MenuItem value="User" sx={{ justifyContent: "flex-start" }}>
                              User
                            </MenuItem>
                            <MenuItem value="Guest" sx={{ justifyContent: "flex-start" }}>
                              Guest
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              src={image || undefined}
                              sx={{ width: 120, height: 120, mb: 2 }}
                            />
                            <input
                              type="file"
                              accept="image/*"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={handleImageChange}
                            />
                            <label htmlFor="fileInput">
                              <Button
                                component="span"
                                style={{
                                  border: "1px solid #EB6247",
                                  color: "#EB6247",
                                  padding: "3px 10px",
                                  borderRadius: "20px",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                  backgroundColor: "#f1f4f8",
                                }}
                              >
                                Choose Image
                              </Button>
                            </label>
                          </Box>
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        sx={{ m: 2 }}
                        style={{
                          border: "1px solid #EB6247",
                          color: "#FFFFFF",
                          backgroundColor: "#EB6247",
                          padding: "8px 16px",
                          borderRadius: "20px",
                          cursor: "pointer",
                        }}
                      >
                        Save Changes
                      </Button>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          py: 2,
          backgroundColor: "#f5f5f5",
        }}
      >
        © 2024 <span className="app-footer-link">10XTECHNOLOGIES</span>. All Rights Reserved
      </Box>
    </Box>
  );
};

export default AccountSettingsForm;
