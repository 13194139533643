import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface AuthInfoState {
  data: any;
}

const initialState: AuthInfoState = {
  data: null,
};

export const authInfoSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchAuthInfo: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearAuthInfo: (state) => {
      state.data = null; 
    },
  },
});

export const { fetchAuthInfo, clearAuthInfo } = authInfoSlice.actions;

export default authInfoSlice.reducer;
