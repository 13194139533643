import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EmailPasswordState {
  email: string;
  password: string;
}

const initialState: EmailPasswordState = {
  email: '',
  password: ''
};

export const emailPasswordSlice = createSlice({
  name: "emailPassword",
  initialState,
  reducers: {
    setEmailPassword: (state, action: PayloadAction<{ email: string; password: string }>) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
  },
});

export const { setEmailPassword } = emailPasswordSlice.actions;

export default emailPasswordSlice.reducer;